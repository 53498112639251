import React, {PropsWithChildren, useState} from 'react'
import styled from '@emotion/styled'
import {StaticQuery, graphql} from 'gatsby'
import {motion} from 'framer-motion'

import ReactMarkdown from 'react-markdown'

import {MEDIA} from '../utils/constants'

import Layout from '../components/mainLayout'
import {ContactMessage, Newsletter} from '../components/ContactForms'
import ContentSection from '../components/ContentSection'
import street from '../assets/street/street3.jpg'
import PageTitle from '../components/PageTitle/PageTitle'

// interface props {
//   style?: React.CSSProperties,
//   data: any,
// };

const contact = ({data}) => {
  // console.log('page_in_lang[0] ',data.strapiWebpage.page_in_lang[0])

  const defaultTitle = 'what do you have to say?'

  let title = data?.strapiWebpage?.page_in_lang[0]?.title
    ? data?.strapiWebpage?.page_in_lang[0]?.title
    : defaultTitle

  return (
    <Layout>
      <Wrapper data-css='contact-Wrapper'>
        <PageTitle text={title} />
        {/* <P data-css="contact-P"> */}
        <ContactContent>
          <CenterDiv>
            <ReactMarkdown
              escapeHtml={false}
              source={data.strapiWebpage.page_in_lang[0].centered_content}
            />
          </CenterDiv>
          <ReactMarkdown
            escapeHtml={false}
            source={data.strapiWebpage.page_in_lang[0].content}
          />
        </ContactContent>
        {/* </P> */}
        <FormContainer>
          <ContactMessage />
        </FormContainer>
      </Wrapper>
    </Layout>
  )
}

export const query = graphql`
  query WebpageContactQuery {
    strapiWebpage(name: {eq: "contact"}) {
      strapiId
      page_in_lang {
        title
        lang
        content
        centered_content
      }
    }
    allStrapiBio {
      edges {
        node {
          role
          name
          content
        }
      }
    }
  }
`

export default contact

const Wrapper = styled.div`
  /* min-height: 100%; */
  /* padding: 0 2rem; */
  /* min-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center; */
`

const ContactContent = styled.div`
  position: relative;
  padding: 5rem 3rem;
  background: whitesmoke;
  border-radius: 5px;
  box-shadow: 3px 3px 15px 2px hsl(0, 0%, 80%);
  font-size: 1.25rem;
  /* width: 100%; */
  /* background: var(--paragraph-bg-color); */
  /* box-shadow: 0px 0px 22px 4px var(--boxshadow-bg-color); */
  /* overflow: hidden; */
  p:first-of-type {
    font-size: 1.75rem;
  }
  @media (min-width: ${MEDIA.tablet}) {
    margin: 0 1rem;
    padding: 5rem 4rem;
  }
  @media (min-width: ${MEDIA.desktop}) {
    margin: 0 2rem;
    padding: 5rem 5rem;
  }
`

const CenterDiv = styled.div`
  text-align: center;
  p:first-of-type {
    font-size: 1.5rem;
  }
`

const FormContainer = styled.div`
  margin: 2rem 0;
  width: 100%;
  @media (min-width: ${MEDIA.tablet}) {
  }
`

// const P = styled.div`
//   width: 90%;
//   grid-column: 1 / 4;
// `;
