import React, {useState, useEffect} from 'react'
import styled from '@emotion/styled'
import {motion, Variants} from 'framer-motion'

import {MEDIA} from '../../utils/constants'
import {emailValidator} from '../../utils/validators'

const errorMessageVariants: Variants = {
  closed: {opacity: 0, y: '-10px', visibility: 'hidden'},
  open: {
    opacity: [1, 1, 1, 1, 0],
    y: [-10, 0, 0],
    visibility: ['visible', 'hidden'],
    transition: {duration: 15},
  },
}

const ContactMessage = () => {
  const [senderName, setSenderName] = useState<string>('')
  const [senderEmail, setSenderEmail] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [validationError, SetValidationError] = useState<any>({
    senderName: false,
    senderEmail: false,
    message: false,
  })
  const [emailError, setEmailError] = useState<boolean>(false)
  const [messageError, setMessageError] = useState<boolean>(false)

  const [flag, setFlag] = useState<boolean>(false)

  useEffect(() => {
    if (senderEmail.length > 0 && senderName.length > 0 && message.length > 0) {
      setFlag(true)
    } else {
      setFlag(false)
    }
  }, [senderName, senderEmail, message])

  const contactHandler = (e) => {
    e.preventDefault()
    if (!emailValidator(senderEmail)) {
      setEmailError(true)
      return
    }
    setMessageError(message.length === 0)
    console.log('flag', flag)
    if (flag) {
      console.log('test')
      // check error
      const data = {
        senderName,
        senderEmail,
        message,
      }

      fetch(`${process.env.GATSBY_API_URL}/contact`, {
        // fetch(`http://localhost:1337/contact`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((res) => res.json())
        .then((res) => {
          console.log('res', res)
          setSenderName('')
          setSenderEmail('')
          setMessage('')
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }

  return (
    <StyledDiv data-css='contact-message'>
      <form onSubmit={contactHandler}>
        <Info>
          <label htmlFor='name'>
            {/* Name */}
            <input
              type='text'
              name='name'
              placeholder='Name'
              value={senderName}
              onChange={(e) => setSenderName(e.target.value)}
              required
            />
          </label>

          <label htmlFor='email'>
            {/* Email */}
            <Input
              type='email'
              name='email'
              placeholder='Email'
              value={senderEmail}
              error={emailError}
              onChange={(e) => {
                setEmailError(false)
                setSenderEmail(e.target.value)
              }}
            />
          </label>
        </Info>
        <TextLabel htmlFor='message'>
          What's on your mind?
          <Textarea
            name='message'
            value={message}
            error={messageError}
            onChange={(e) => {
              setMessageError(false)
              setMessage(e.target.value)
            }}
            style={{resize: 'none'}}
            rows={5}
          />
        </TextLabel>
        <SubmitBtn
          type='submit'
          flag={flag}
          disabled={!flag}
          whileHover={{scale: 1.1}}
          whileTap={{scale: 0.9}}
        >
          Send
        </SubmitBtn>
      </form>
      <Error
        data-css='error'
        animate={emailError ? 'open' : 'closed'}
        variants={errorMessageVariants}
      >
        <p>{emailError ? "don't forget to enter an email address" : ''}</p>
      </Error>
    </StyledDiv>
  )
}

export default ContactMessage

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  /* margin: 5rem auto; */
  background-color: white;
  padding: 1rem;
  /* margin: 2rem 0; */
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.19);

  label {
    display: flex;
    flex-direction: column;
    align-items: left;
    /* width: 100%;
    margin: 0.5rem; */
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: ${MEDIA.tablet}) {
    width: 75%;
    margin: 1rem auto;
  }
`

const Info = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  label {
    width: 45%;
    margin: 0;
  }
  input {
    /* height: 2rem; */
    padding: 0.5rem;
  }
  @media (min-width: ${MEDIA.tablet}) {
    /* flex-direction: row; */
  }
`
const TextLabel = styled.label`
  width: 100%;
  margin-top: 1rem;
  font-size: 0.85rem;
`

const SubmitBtn = styled(motion.button)<{flag: boolean}>`
  height: 2rem;
  margin: 0.5rem auto;
  background-color: #0f4c81;
  color: whitesmoke;
  border: none;
  border-radius: 0.25rem;
  filter: ${(prop) => (prop.flag ? 'none' : 'grayScale(100%)')};
  background-color: ${(prop) => (prop.flag ? '#0F4C81' : 'lightgray')};
  &:hover {
    cursor: ${(prop) => (prop.flag ? 'pointer' : 'not-allowed')};
  }
`

const Input = styled.input<{error: boolean}>`
  border: 1px solid ${(prop) => (prop.error ? 'red' : 'transparent')};
`

const Textarea = styled.textarea<{error: boolean}>`
  border: 1px solid ${(prop) => (prop.error ? 'red' : 'transparent')};
  padding: 0.5rem;
`

const Error = styled(motion.div)<() => any>`
  position: absolute;
  margin: 0 auto;
  width: 100%;
  left: 0;
  p {
    background-color: red;
    width: fit-content;
    margin: 0 auto;
  }
`
